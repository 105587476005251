import React, { Component } from 'react';

import { TextLoader } from '../../Loader/Loader';
import { Button } from 'gw-ui';

class StockistCard extends Component {

	state = {
		loading : true
	}

	setClassName () {
		let className = 'stockist-card-wrapper'

		if(this.props.className) {
			className += ' ' + this.props.className
		}
		return className;
	}

	formatAddressFields = (addressComponents, fullAddress) => {
		let streetNumber;
		let streetName;
		let locality;
		let state;
		let zipCode;
		let country;

		addressComponents.map((addy, index) => {
			addy.types.includes('street_number') ? streetNumber = addy.long_name : false
			addy.types.includes('route') ? streetName = addy.long_name : false
			addy.types.includes('locality') ? locality = addy.long_name : false
			addy.types.includes('administrative_area_level_1') ? state = addy.short_name : false
			addy.types.includes('postal_code') ? zipCode = addy.long_name : false
			addy.types.includes('country') ? country = addy.short_name : false
		})

		if ( !this.props.mobile && streetNumber && streetName && locality && state && zipCode && country ){
			return (
				<h3 className="p location-address medium-weight mt-1">{streetNumber + " " + streetName} <br/> {locality + ", " + state + " " + zipCode + ", " + country}</h3>
			)
		} else {
			return (
				<h3 className="ellipsis p location-address medium-weight mt-1">{fullAddress}</h3>
			)
		}
	}

	render() {

		const { loadingGooglePlace, stockist, selectedGooglePlace } = this.props;
		if ( stockist === null) {
			return null;
		} 
		return (
			<div className={this.setClassName()}>
				<div className="mobile-close"><span className="material-icons" onClick={this.props.closeInfoWindow}>close</span></div>
				<h2 className="ellipsis p location-name">{stockist.fields.internalName}</h2>
				{selectedGooglePlace ? this.formatAddressFields(selectedGooglePlace.address_components, stockist.fields.address) : false}
				{selectedGooglePlace && selectedGooglePlace.formatted_phone_number ? (
					<a className="p link ellipsis mt-1 location-phone" href={'tel:' + selectedGooglePlace.formatted_phone_number}>T {selectedGooglePlace.formatted_phone_number}</a>
				) : false}
				{selectedGooglePlace && selectedGooglePlace.website ? (
					<a className="p link ellipsis mt-1" href={selectedGooglePlace.website} target="_blank">{selectedGooglePlace.website}</a>
				) : false}
				<div className="grid-flex left mt-2">
					<div className="col">
					{selectedGooglePlace && selectedGooglePlace.url ? (
						<Button href={selectedGooglePlace.url} target="_blank" label={this.props.lang == "en-US" ? "Directions" : "Indicaciones"} className="secondary"/>
					) : false}
					</div>
				</div>
			</div>
		);
	}
}

export default StockistCard;
