import React, { Component } from 'react';

import Illo from 'assets/svg/on-premise.svg';

class OnPremise extends Component {
	className () {
		let className = 'illo-wrap';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

  render() {
    return (
      <div className={this.className()}>
      	<Illo />
      </div>
    );
  }
}

export default OnPremise;