import React, { useState, useContext, useEffect, useRef, Fragment } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow, useGoogleMap } from '@react-google-maps/api'

import mapStyle from 'components/Stockists/mapStyle.js';
import StockistCard from './components/StockistCard.js';
import OnPin from 'assets/svg/pins/on-pin.svg';
import OffPin from 'assets/svg/pins/off-pin.svg';

import { FinderContext } from 'store/FinderState';

const libraries = ['places'];

function MyComponent(props) {

	const setRegion = (locale) => {
		var region;
		if (locale === 'en-US') {
			region = 'US'
		} else {
			region = 'MX';
		}

		return region;
	}

  const { isLoaded, loadError } = useLoadScript({
  	googleMapsApiKey: (`${process.env.GATSBY_GOOGLE_API_KEY}`),
    libraries: libraries,
    region: setRegion(props.locale)
  })

  const onMapChange = (map) => {
  	if (map) {
			if (!props.loadingStockists && finder.selectedStockist === null) {
				const bounds = map.getBounds();
				props.queryBounds(bounds);
			}
			const center = map.getCenter();
			setMapCenter(center);
  	}
  }

  const [map, setMap] = useState(
  	null
	);

	const [activeMarker, setActiveMarker] = useState(
  	null
	);

	const [zoom, setZoom] = useState(
  	14
	);

	const [isOpen, setIsOpen] = useState(
  	false
	);

	const [selectedGooglePlace, setSelectedGooglePlace] = useState(
  	null
	);

	const [loadingGooglePlace, setLoadingGooglePlace] = useState(
  	false
	);

	const [infoOffset, setInfoOffset ] = useState(
		null
	);

	const [mapCenter, setMapCenter ] = useState(
		null
	);

	const [mapBounds, setMapBounds ] = useState(
		null
	);

  const finder = useContext(FinderContext);
  const mobile = props.mobile;

  const markerRefs= useRef([]);

  if (finder.selectedStockist) {
  	setActiveMarker(finder.selectedStockist);
  }

	useEffect(() => {
		setMapCenter(props.location);
	}, [props.location])  

	useEffect(() => {
		setZoom(props.zoom)
	}, [props.zoom])  

	useEffect(() => {
		if (map) {
			map.fitBounds(props.bounds)
		}
	}, [props.bounds])  

	useEffect(() => {
		setActiveMarker(props.selectedStockist);
		if (map) {
			if(props.mobile){
				map.panBy(0, -3)
			} else {
				map.panBy(0, -10)
			}
		}
	}, [props.selectedStockist])  

	useEffect(() => {
		if(props.infoWindowOpen === true ) {
			setIsOpen(true)
		}
	}, [props.infoWindowOpen])


	const handleMarkerClick = (stockist) => {
		props.handleStockistClick(stockist);
			setIsOpen(true);
	}

	const handleInfoWindowClose = () => {
		props.closeInfoWindow();
		setIsOpen(false);
	}

	const fitBounds = map => {
		// console.log('fitBounds method called')
		// if ( finder.stockists.length > 0 ){

	 //    const bounds = new window.google.maps.LatLngBounds();
	 //    finder.stockists.map(place => {
	 //    	let placeLatLng = new google.maps.LatLng(place.fields.location.lat, place.fields.location.lon);
	 //      bounds.extend(placeLatLng);
	 //    });
	 //    setMapBounds(bounds);
	 //    // map.fitBounds(mapBounds);
	 //    console.log('mapBounds', mapBounds)
		// }
  };

  const handleMapLoad = (map) => {
	  setMap(map);
	  // fitBounds(map)
	  // finder.stockists.length > 1 ? fitBounds(map) : false;
	  // map.fitBounds(mapBounds);
	}

  const renderMap = () => {
  	// console.log('finder stockists : ', finder.stockists)
   //  console.log('mapBounds', mapBounds)
  	var infoOffset = null;
  	if(finder.google) {
  		infoOffset = new finder.google.maps.Size(0,-33);
  	}

  	const infoOptions = {
  		pixelOffset : infoOffset
  	}
  	
    return <GoogleMap
      id="finder-google-map"
	    mapContainerStyle={{
	      height: "100%",
	      width: "100%"
	    }}
	    zoom={zoom}
	    center={mapCenter}
	    onLoad={handleMapLoad}
	    onDragEnd={()=> onMapChange(map)}
	    onZoomChanged={()=> onMapChange(map)}
	    options={{ 
	    	styles: mapStyle.monopolio,
	    	disableDefaultUI: true,
	    	zoomControl: props.mobile ? false : true,
	    	mapTypeControl: false,
			  scaleControl: true, 
			  minZoom: 10
	    }}
	    // ref={(map) => { console.log(map); map.fitBounds(mapBounds); }}
    >
      {
      	finder.stockists.map((stockist, index) => {
      		return(
      			<Fragment key={stockist.sys.id}>
		      		<Marker
			      		ref={node => (markerRefs.current[stockist.sys.id] = node)}
			      		id={stockist.sys.id}
								position={{lat : stockist.fields.location.lat, lng : stockist.fields.location.lon}}
		      			onClick={() => handleMarkerClick(stockist)}
		      			stockist={stockist}
		      			icon={{
							    url: OnPin
							  }}
		      		/>

		      		{ isOpen && finder.selectedGooglePlace && !mobile && <InfoWindow
										position={{lat : activeMarker.fields.location.lat, lng : activeMarker.fields.location.lon}}
										onCloseClick={handleInfoWindowClose}
										options={infoOffset ? infoOptions : null}
							    >
							    	<StockistCard
							    		stockist={activeMarker}
							    		selectedGooglePlace={finder.selectedGooglePlace}
							    		lang={props.locale}
							    		loadingGooglePlace={props.loadingGooglePlace}
							    	/>

							    </InfoWindow>
				      }

				    </Fragment>
    			)
      	})
      }
    </GoogleMap>
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  if (isLoaded && !finder.google) {
  	finder.loadGoogleMap(window.google);
  }

  return isLoaded ? renderMap() : false
}

export default MyComponent;