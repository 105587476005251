import React, { Component } from 'react';

import { Async } from 'react-select'
// import AsyncSelect from 'react-select/async';
import { FinderContext } from 'store/FinderState';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#F5F5F5' : 'white',
      color: isFocused ? '#342C21' : '#B89368',
      paddingLeft: 22
    };
  },
  menu: styles => ({
    ...styles,
    marginTop: 0
  }),
  menuList: (styles, state) => ({
	  ...styles,
	  paddingTop: 0,
	  paddingBottom: 0,
	})
};

export class StockistsInput extends Component {

	state = {
		selectedOption : this.context.selectedOption,
		options : [],
		menuIsOpen : false,
		stockistsIntro : false
	}

	setClassName () {
		let className = 'text stockists-search input-wrap'

		if(this.props.className) {
			className += ' ' + this.props.className
		}
		return className;
	}

	componentWillMount () {
		if(this.context.google != null){
			this.initPlaceAutoComplete();
		}
		this.setState({ 
			stockistsIntro : this.props.stockistsIntro
			// selectedOption : this.props.stockists
		});
	}

	componentDidUpdate() {
    // console.log('componentDidUpdate');
  }

	componentWillReceiveProps (nextProps) {
		if(this.context.google != null) {
			this.initPlaceAutoComplete(this.context.google);
		}
	}

	getPlacePredictions = (input) => {
		if(!this.context.google) {
			return []
		}

		return new Promise( (resolve, reject) => {
			var region;
			if(this.props.locale === 'en-US') {
				region = 'US'
			} else {
				region = 'MX'
			}

			if(input.length > 3) {
				
				const request = {
					input : input,
					//types: ,
					componentRestrictions: {country: region}
				};

				if(!input.includes(' ')) {
					request.types = ['(regions)'];
				}

				this.autocomplete.getPlacePredictions(request, (result) => {

					const options = result ? result.map((item) => {
						return {
							label : item.description,
							value : item.id,
							location : item
						};
					}).sort( (a, b) => {

						const aL = a.location.types.includes('street_address') || a.location.types.includes('establishment') || a.location.types.includes('route') || a.location.types.includes('premise');
						const bL = b.location.types.includes('street_address') || b.location.types.includes('establishment') || b.location.types.includes('route') || b.location.types.includes('premise');

						if ( aL && !bL ) {
							return 1;
						}

						if (!aL && bL ) {
							return -1;
						}

						return 0;

					}) : [];
					this.setState({menuIsOpen : true})
					this.setState({options});
					resolve(options);
					// return options;
				});
				
			} else {
				this.setState({options: []});
				resolve([]);
			}

		});
	}


	initPlaceAutoComplete ()  {
		if (this.context.google != null) {
			this.autocomplete = new this.context.google.maps.places.AutocompleteService();
		}
	}

	handleTypeaheadChange = (option) => {
		this.setState({
			selectedOption : option,
			menuIsOpen: false
		})
		if (option.location) {
			this.props.setLocation(option.location);
			this.context.setSelectedOption(option);
		}
	}

	handleKeyDown = (event) => {
		const {options} = this.state;
		if(this.props.handleKeyDown) {
			this.props.handleKeyDown(event, options)
		}
	}

	handleBlur = () => {
		// console.log('input handleBlur')
		this.setState({menuIsOpen: false})
		const {options} = this.state;
		const location = options[0] && options[0].location;
		// console.log('handleBlur: ', this.props.handleBlur);
		// console.log('LOCATION L L', location )
		// if(this.props.handleBlur) {
			this.props.handleBlur(location, options[0]);
			// this.context.setSelectedOption(options[0]);
		// }
		// console.log("this.context", this.context)
		// console.log("this.state", this.state)
	}

	handleMenuOpen = () => {
		this.clearSelectedOption();
		if(this.props.onMenuOpen) {
			this.props.onMenuOpen();
		}
		// this.props.focusList();
	}

	onInputFocus = () => {
		this.props.focusList(this.state.menuIsOpen, this.state.stockistsIntro);
		this.clearSelectedOption();
	}

	clearSelectedOption = () => {
		this.setState({selectedOption:null});
		// this.context.setSelectedOption(null);

	}

	selectComponents = () => {
		const DropdownIndicator = () => null;
		const IndicatorsContainer = () => null;
		const IndicatorSeparator = () => null;
		const NoOptionsMessage = () => null;
		const LoadingMessage = (props) => {
			return (
				<div style={props.getStyles('loadingMessage', props)}></div>
			);
		};

		let components = { LoadingMessage, NoOptionsMessage, DropdownIndicator, IndicatorsContainer, IndicatorSeparator };

		if (this.props.Option) {
			components['Option'] = this.props.Option;
		}

		if (this.props.Menu) {
			components['Menu'] = this.props.Menu;
		}

		return components;
	}


	render() {

		return (
			<div className={this.setClassName()}>
				<Async
					className="gw-react-select-container"
					classNamePrefix="gw-react-select"
					name="location"
					placeholder={this.context.selectedOption ? this.context.selectedOption.label : 'Enter Zipcode'}
					clearable={false}
					loadOptions={this.getPlacePredictions}
					value={this.state.selectedOption}
					onChange={this.handleTypeaheadChange}
					onInputChange={this.clearSelectedOption}
					components={this.selectComponents()}
					// openMenuOnClick={false}
					autoFocus={this.props.autoFocus}
					hideSelectedOptions={true}
					onMenuOpen={this.handleMenuOpen}
					onMenuClose={this.props.unfocusList}
					onFocus={this.onInputFocus}
					onBlur={this.handleBlur}
					onKeyDown={this.handleKeyDown}
					styles={colourStyles}
					menuIsOpen={this.state.menuIsOpen}
					maxMenuHeight='100%'
					autosize={false}
					inputFocused={this.props.inputFocused}
				/>

			</div>
		);
	}
}

StockistsInput.contextType = FinderContext;

export default StockistsInput;