import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby';
import Helmet from 'react-helmet'
import * as contentful from 'contentful';
import { debounce } from 'debounce';
import { CSSTransition } from 'react-transition-group'
import { animateScroll as scroll } from 'react-scroll'

import Header from 'components/Header';
import Footer from 'components/Footer';
import StockistsIntro from 'components/StockistsIntro';
import GoogleMap from 'components/Stockists/GoogleMap'
import StockistCard from 'components/Stockists/components/StockistCard.js';
import StockistsInput from 'components/StockistsIntro/components/StockistsInput'
import OnPremise from 'components/StockistsIntro/components/OnPremise'
import OffPremisePin from 'components/Stockists/components/OffPremisePin'

import mapStyle from 'components/Stockists/mapStyle.js';

import 'components/Stockists/Stockists.scss';

const client = contentful.createClient({
	space: (`${process.env.GATSBY_CONTENTFUL_SPACE}`),
	accessToken: (`${process.env.GATSBY_CONTENTFUL_DELIVERY_ACCESS_TOKEN}`),
});


import { FinderContext } from 'store/FinderState';

class FinderTemplate extends Component {

	state = {
		mobile: false,
		intro: true,
		// getCurrentLocation: true,
		zoom: 14,
		location : {
			lat : 39.217119,
			lng : -97.097735
		},
		bounds : null,
		placeId : null,
		loadingStockists: false,
		loadingGooglePlace: false,
		selectedStockist: null,
		infoWindowOpen : false,
		inputFocused : false
	}

	componentDidMount () {
		if (typeof window !== `undefined`) {
			this.toggleMobileComparision();
			window.addEventListener('resize', this.toggleMobileComparision);
		}
	}

	componentWillUnmount () {
    window.removeEventListener('resize', this.toggleMobileComparision);
  }

	componentDidUpdate () {
		if (this.context.google) {
			this.initGeocorder(this.context.google);
			this.initPlaceService(this.context.google);
		}
	}

	toggleMobileComparision = debounce(() => {
		if (typeof window !== `undefined`) {
	  	this.setState({windowHeight : window.innerHeight})
	    if(window.innerWidth > 769) {
	      this.setState({ mobile : false });
	    } else {
	      this.setState({ mobile : true });
	    }
	  }
  })

  focusList = (menuIsOpen, stockistsIntro) => {
  	// console.log('focusList: ', menuIsOpen)
		this.setState({inputFocused : true})
		
		if (this.state.mobile && !menuIsOpen && stockistsIntro) {
			scroll.scrollToBottom();
		}
	}

	unfocusList = () => {
		this.setState({inputFocused : false})
	}

	initGeocorder (google) {
		this.geocoder = new google.maps.Geocoder();
	}

	initPlaceService (google) {
		this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
	}

	getGooglePlace (placeId) {
		this.context.setGooglePlace({
			loadingGooglePlace : true,
			selectedGooglePlace : null
		})

		this.placeService.getDetails({placeId}, (results, status) => {
			this.context.setGooglePlace({
				loadingGooglePlace : false,
				selectedGooglePlace : results
			});
		});
	}

	setLocation = (place) => {

		this.setState({settingLocation:true});

		this.geocoder.geocode({'placeId': place.place_id}, (results, status) => {
			const geoLocation = results[0].geometry.location;
			// console.log('setLocation', results)
			const bounds = results[0].geometry.bounds;
			
			if (bounds) {
				this.setState({
					placeId : geoLocation.place_id,
					location: geoLocation,
					bounds
				});
			} else {
				// const zoom = 14;
				this.setState({
					placeId : location.place_id,
					location : geoLocation,
					// zoom
				});
			}


			if (place.types.includes('street_address')) {
				this.queryRadius(geoLocation, 5);
			} else if (place.types.includes('postal_code')) {
				this.queryRadius(geoLocation, 5);
				// this.queryNear(location);
			} else if (bounds) {
				this.queryBounds(bounds);
			} else {
				this.queryNear(geoLocation);
			}

			this.setState({
				intro: false,
				inputFocused: false
			})
		})
	}

	queryNear = (geoLocation) => {
		const query = {'fields.location[near]' : geoLocation.toUrlValue(), 'limit' : 75}
		this.getStockists(query, 'near', geoLocation);
	}

	queryRadius = (geoLocation, radius) => {
		// console.log('queryRadius location:', geoLocation)
		const query = {'fields.location[within]' : `${geoLocation.toUrlValue()},${radius}`, 'limit' : 75}
		this.getStockists(query, 'radius', geoLocation);
	}

	queryBounds = (bounds) => {
		const query = {'fields.location[within]' : bounds.toUrlValue(), 'limit' : 75}
		this.getStockists(query, 'bounds');
	}

	expandSearch = (geoLocation) => {
		// console.log("geoLocation",geoLocation)
		const radius = 40;
		const query = {'fields.location[within]' : `${geoLocation.toUrlValue()},${radius}`, 'limit' : 75}

		const params = Object.assign({'content_type' : 'beerFinderItem'}, query);
		client.getEntries(params)
		.then( (results) => {
			
			const noStockists = results.items.length > 0 ? false : true;

			this.context.setStockists(results.items, noStockists);
			// console.log('getStockists result items: ', results.items);
			this.stockistsBounds();
			this.setState({
				loadingStockists : false
			});
		})
		.catch(err => console.log(err));
	}

	getStockists (query, queryType, geoLocation) {
		// console.log('getStockists geoLocation:', queryType)
		this.setState({loadingStockists:true});

		const params = Object.assign({'content_type' : 'beerFinderItem'}, query);
		client.getEntries(params)
		.then( (results) => {
			
			const noStockists = results.items.length > 0 ? false : true;
			if ( noStockists ) {
				this.expandSearch(geoLocation);
			} else {

				this.context.setStockists(results.items, noStockists);
				// console.log('getStockists result items: ', results.items);

				if (queryType != 'bounds') {
					this.stockistsBounds();
				}
				this.setState({
					loadingStockists : false
				});
			}
		})
		.catch(err => console.log(err));
	}

	stockistsBounds = () => {
		if ( !this.context.noStockists ) {

			var markers = this.context.stockists;
			var bounds = new google.maps.LatLngBounds();
			for (var i = 0; i < markers.length; i++) {
				let markerLatLng = new google.maps.LatLng(markers[i].fields.location.lat, markers[i].fields.location.lon);
			 bounds.extend(markerLatLng);
			}

			const boundsCenter = bounds.getCenter();

			if (markers.length > 1 ) {
				this.setState({
					bounds: bounds,
					location: boundsCenter
				})
			} 
			// this.setState({
			// 	bounds: bounds,
			// 	location: boundsCenter
			// });
		}
	}

	selectStockist = (stockist) => {
		const { google } = this.context;
		const { location } = stockist.fields;
		const { placeId } = stockist.fields;
		if ( placeId ) {
			this.getGooglePlace(placeId);
		}

		// Zoom / Center Map on Stockist Marker
		this.setState({
			selectedStockist : stockist,
			zoom : 18,
			location : new google.maps.LatLng(location.lat, location.lon)
		});
	}

	setError () {
		this.setState({
			error : true
		});
		setTimeout(()=> {
			this.setState({
				error : false
			});
		},1000);
	}

	handleKeyDown = (event, options) => {
		if (event.keyCode === 13 && options.length === 0) {
			this.setError();
		}
	}

	handleBlur = (location, option) => {
		// console.log('templaate handleBlur');
		const { mobile } = this.state;
		// console.log('mobile:', mobile);

		if (mobile && location) {
			this.setLocation(location);
			this.context.setSelectedOption(option);
			// this.props.removeIntro();
		} else if (mobile) {
			// this.props.removeIntro();
		} else {
			this.setError();
		}
	}

	handleStockistClick = (stockist) => {
		this.selectStockist(stockist);
		this.setState({infoWindowOpen: true});
	}

	closeInfoWindow = () => {
		this.setState({
			infoWindowOpen: false,
			selectedStockist: null,
			zoom: 14
		});
		this.context.setGooglePlace(false, null)
	}

	getCurrentLocation = () => {

		if (this.state.currentLocation) {
			this.setState({
				location : this.state.currentLocation
			});
		} else if (navigator && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition( (position) => {

				var latlng = {lat: position.coords.latitude, lng: position.coords.longitude};

				this.geocoder.geocode({'location': latlng}, (results, status) => {
					const location = results.find( (place) => {
						return place.types.includes('neighborhood') || place.types.includes('postal_code')
					});

					if (location) {
						this.setLocation(location);
						this.setState({
							intro: false,
							currentLocation : latlng,
							inputFocused: false
						});
					}
				});
			})
		}
	}

	setHelmetLocale = (locale, page, rootPath) => {
  	let title;
  	let favicon;
  	let og_locale;
  	let touchIcon;
  	let defaultShareImage;
  	if( locale === 'en-US') {
  		title = this.props.data.usSettings.edges[0].node.title
  		favicon = this.props.data.usSettings.edges[0].node.favicon.file.url
  		touchIcon = this.props.data.usSettings.edges[0].node.touchIcon.file.url
  		defaultShareImage = this.props.data.usSettings.edges[0].node.defaultShareImage.file.url
  		og_locale = 'en_US';
  	} else {
  		title = this.props.data.mxSettings.edges[0].node.title
  		favicon = this.props.data.mxSettings.edges[0].node.favicon.file.url
  		touchIcon = this.props.data.usSettings.edges[0].node.touchIcon.file.url
  		defaultShareImage = this.props.data.usSettings.edges[0].node.defaultShareImage.file.url
  		og_locale = 'es_MX';
  	}

  	return (
  		<Helmet
  			bodyAttributes={{
		        class: 'finder-page-wrapper'
		    }}
  		>
					<meta charset="utf-8"/>
					<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
	  			<title>{title}</title>
	  			<link rel="shortcut icon" href={favicon}/>
					<meta name="description" content={page.seoDescription} />
					<link rel="apple-touch-icon-precomposed" href={'https:' + touchIcon} />
					<link rel="canonical" href={rootPath} />
					<meta name="viewport" content="initial-scale=1.0, user-scalable=no" />
					<meta property="og:image" content={'https:' + defaultShareImage} />
					<meta property="og:locale" content={og_locale} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={page.seoDescription}/>
					<meta property="og:url" content={rootPath} />
					<meta property="og:site_name" content={title} />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:description" content={page.seoDescription} />
					<meta name="twitter:title" content={title} />
        </Helmet>
  	)
  }

	render() {
		const page = this.props.data.allContentfulPage.edges[0].node;
		const contentfulStockistsIntro = page.sections.find(i => i.__typename === "ContentfulBeerFinderIntro");
		const rootPath = this.props.location.href;
		// console.log("state bounds ",this.state.zoom);
		return (
			<Fragment>

				{this.setHelmetLocale(page.node_locale, page, rootPath)}
				
				<Header lang={page.node_locale} className={this.state.intro ? 'finder-header intro' : 'finder-header'} finder={true}/>

				<div className={this.state.intro ? 'page-stockists intro' : 'page-stockists'}>
					<StockistsIntro
						locale={page.node_locale}
						content={contentfulStockistsIntro}
						mobile={this.state.mobile}
						setLocation={this.setLocation}
						handleBlur={this.handleBlur}
						handleKeyDown={this.handleKeyDown}
						geocoder={this.geocoder}
						setLocation={this.setLocation}
						getCurrentLocation={this.getCurrentLocation}
						focusList={this.focusList}
						unfocusList={this.unfocusList}
					/>
					
					<div className="stockists-finder">
						<div className={this.state.infoWindowOpen ? "stockists-map info-open" : "stockists-map"}>
							<GoogleMap
								mobile={this.state.mobile}
								locale={page.node_locale} 
								bounds={this.state.bounds}
								location={this.state.location}
								zoom={this.state.zoom}
								placeId={this.state.placeId}
								loadingStockists={this.state.loadingStockists}
								loadingGooglePlace={this.state.loadingGooglePlace}
								handleStockistClick={this.handleStockistClick}
								selectedStockist={this.state.selectedStockist}
								fitBoundsPadding={this.props.mobile ? 100 : 200}
								infoWindowOpen={this.state.infoWindowOpen}
								closeInfoWindow={this.closeInfoWindow}
								queryBounds={this.queryBounds}
							/>
						</div>

						<div className={this.state.infoWindowOpen ? "stockists-list info-open" : "stockists-list" }>
							<div className="stockists-list-top-area">
								
								<div className="stockists-list-search-wrap">
									<StockistsInput
										locale={page.node_locale}
										handleBlur={this.handleBlur}
										handleKeyDown={this.handleKeyDown}
										setLocation={this.setLocation}
										focusList={this.focusList}
										unfocusList={this.unfocusList}
										inputFocused={this.state.inputFocused}
										className="large"
										stockistsIntro={false}
										// selectedOption={this.context.stockists}
									/>
								</div>
								{/*<div className="map-legend-wrapper hide-md">
									<div className="map-legend">
										<div className="map-legend-item">
											<div className="icon">
												<OffPremisePin className="on-premise"/>
											</div>
											<p className="legend-text">On Premise</p>
										</div>
										<div className="map-legend-item ml-2 pr-1">
											<div className="icon">
												<OffPremisePin className="off-premise"/>
											</div>
											<p className="legend-text">Off Premise</p>
										</div>
									</div>
								</div>*/}
							</div>

							{/*
								<CSSTransition in={this.state.infoWindowOpen} timeout={300} className="stockists-list-wrap">
									<div>
							*/}
								<div className={this.state.infoWindowOpen ? "stockists-list-wrap info-open" : "stockists-list-wrap" }>
									{this.context.noStockists ? (
										<div className="empty-list align-center">
											<div className="grow">
												{this.props.mobile ? (
													<h2>We could not find<br/> any Monopolio near you.</h2>
												):(
													<h4>We could not find<br/> any Monopolio near you.</h4>
												)}
											</div>
										</div>
										) :
										(
											<div className={this.state.inputFocused ? "stockist-items input-focused" : "stockist-items"}>
											{this.context.stockists.map((stockist, index) => {
												if (stockist.sys && stockist.fields.internalName) {
													const { selectedStockist } = this.context;
													const active = this.state.selectedStockist && stockist.sys.id === this.state.selectedStockist.sys.id;
													return(
														<div onClick={() => this.handleStockistClick(stockist)} className={active ? "item active" : "item"} key={stockist.sys.id}>
															<div className="stockist-item grid-flex no-break gutter-none top">
																<div className="col no-grow">
																	<div className="icon">
																		<OffPremisePin className="on-premise"/>
																	</div>
																</div>
																<div className="col grow ellipsis">
																	<p className="ellipsis bold title">{stockist.fields.internalName}</p>
																	<p className="ellipsis sm address medium-weight">{stockist.fields.address ? stockist.fields.address : '' }</p>
																</div>
															</div>
														</div>
													)
												} else {
													return false;
												}
											})}
											</div>
										)
									}

					      { this.state.mobile && <CSSTransition in={this.state.infoWindowOpen} timeout={300} className="mobile-stockist-card" unmountOnExit>
					      	<StockistCard
						    		lang={page.node_locale}
						    		stockist={this.state.selectedStockist}
						    		selectedGooglePlace={this.context.selectedGooglePlace}
						    		closeInfoWindow={this.closeInfoWindow}
						    		mobile={this.state.mobile}
						    		// loadingGooglePlace={this.context.loadingGooglePlace}
						    		// className={this.state.infoWindowOpen ? "mobile visible" : "mobile"}
						    	/>
						    	</CSSTransition>
					      }
								</div>
							{/*</CSSTransition>*/}
						</div>
					</div>

				</div>

			</Fragment>
		);
	}
}

export const pageQuery = graphql`
	query($id: String!) {
		usSettings : allContentfulSiteSettings(filter: {node_locale: {eq: "en-US"}}) {
			edges {
				node {
					title
					footerSections {
	          id
	          displayTitle
	          description {
	          	json
	          }
	          ... on ContentfulList {
	            id
	            items {
	             ... on ContentfulButton {
	             	 	id
	              	label
	              	to
	              	external
	              	theme
	            	} 
	            }
	          }
	        }
	        defaultShareImage {
	          id
	          file {
	            url
	          }
	        }
	        favicon {
	        	file {
	        		url
	        	}
					}
	        footerNewsletterTitle
	        footerNewsletterText {
	          footerNewsletterText
	        }
	        showNewsletterSignUp
	        touchIcon {
	          file {
	            url
	          }
	        }
				}
			}
		}
		mxSettings : allContentfulSiteSettings(filter: {node_locale: {eq: "es-MX"}}) {
			edges {
				node {
					title
					footerSections {
	          id
	          displayTitle
	          description {
	          	json
	          }
	          ... on ContentfulList {
	            id
	            items {
	             ... on ContentfulButton {
	             	 	id
	              	label
	              	to
	              	external
	              	theme
	            	} 
	            }
	          }
	        }
	        defaultShareImage {
	          id
	          file {
	            url
	          }
	        }
	        favicon {
	        	file {
	        		url
	        	}
					}
	        footerNewsletterTitle
	        footerNewsletterText {
	          footerNewsletterText
	        }
	        showNewsletterSignUp
	        touchIcon {
	          file {
	            url
	          }
	        }
				}
			}
		}
		allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					node_locale
					path
					title
					seoDescription
					shareImage {
	          id
	          fluid(quality: 100) {
				      aspectRatio
				      src
				      srcSet
				    }
	        }
	        sections {
	          ... on ContentfulBeerFinderIntro {
	            id
	            introHeader
	            introText {
	              id
	              json
	            }
	            introImage {
	            	id
	              image {
	              	fluid(quality: 100) {
							      aspectRatio
							      src
							      srcSet
							    }
	              }
	              useMultipleImages
	            }
	          }
	        }
				}
			}
		}
	}
`

FinderTemplate.contextType = FinderContext;

export default FinderTemplate;