import React, { Component, Fragment } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import Image from 'components/Image'
import GoogleMap from 'components/Stockists/GoogleMap'
import StockistsInput from './components/StockistsInput'
import { FinderContext } from 'store/FinderState';

import './StockistsIntro.scss';


class StockistsIntro extends Component {

	state = {
		testMapInstance: null,
		error : false
	};

	setError () {
		this.setState({
			error : true
		});
		setTimeout(()=> {
			this.setState({
				error : false
			});
		},1000);
	}

	handleKeyDown = (event, options) => {
		if (event.keyCode === 13 && options.length === 0) {
			this.setError();
		}
	}

	// handleBlur = (location) => {
	// 	const { mobile } = this.props;

	// 	if (mobile && location) {
	// 		this.props.setLocation(location);
	// 		this.props.removeIntro();
	// 	} else if (mobile) {
	// 		this.props.removeIntro();
	// 	} else {
	// 		this.setError();
	// 	}
	// }

	render () {
		const {
			introHeader,
			introText,
			introImage
		} = this.props.content;

		if (!this.context.google) {
			return false;
		}

		return (
			<div className="stockists-intro">
				<div className="grid-flex gutter-none break-lg">
					<div className="col-8 order-2">
						<div className="intro-image-wrap">
							<Image {...introImage} />
						</div>
					</div>

					<div className="col-4 order-1">
						<div className="intro-text-wrap">
							<h1 className="h3 intro-header">{introHeader}</h1>
							<div className="mt-2 intro-text rich-text" dangerouslySetInnerHTML={{ __html: documentToHtmlString(introText.json) }} />
							<StockistsInput
								locale={this.props.locale}
								handleBlur={this.props.handleBlur}
								handleKeyDown={this.props.handleKeyDown}
								setLocation={this.props.setLocation}
								focusList={this.props.focusList}
								autoFocus={false}
								stockistsIntro={true}
							/>
							<h2 className="find-location mt-2 h5" onClick={this.props.getCurrentLocation}><span className="material-icons icon">gps_fixed</span>Find my Location</h2>
						</div>
					</div>
					
				</div>
			</div>
		)
	}

}

StockistsIntro.contextType = FinderContext;
export default StockistsIntro;